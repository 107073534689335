import React from "react";
import Styled from "./Styles";
import imgRecepcion from "media/icons/recepcion-pagos.png";
import imgRetiro from "media/icons/retiro-fondos.png";
import imgPlataforma from "media/icons/plataforma.png";
const TokenizationFeatures = ({ textColor = "white" }) => {
  return (
    <Styled.ContainerMembers>
      <Styled.ContainerTeamMember>
        <Styled.ImageMember src={imgRecepcion} />
        <Styled.TeamMemberPosition>
          Dividir un proyecto en partes
        </Styled.TeamMemberPosition>

        <Styled.TeamMemberDescription textColor={textColor}>
          Se puede dividir en cualquier cantidad de partes, según el acceso que
          quiera brindar a la inversión y el monto que se necesita para el
          proyecto.
        </Styled.TeamMemberDescription>
      </Styled.ContainerTeamMember>
      <Styled.ContainerTeamMember>
        <Styled.ImageMember src={imgRetiro} />
        <Styled.TeamMemberPosition>
          Búsqueda de Capital
        </Styled.TeamMemberPosition>

        <Styled.TeamMemberDescription textColor={textColor}>
          La tokenización tiene como objetivo principalmente ser una alternativa
          de búsqueda de capital e inversión. Orientada a los micro inversores y
          entregar beneficios por esa inversión.
        </Styled.TeamMemberDescription>
      </Styled.ContainerTeamMember>
      <Styled.ContainerTeamMember>
        <Styled.ImageMember src={imgPlataforma} />
        <Styled.TeamMemberPosition>
          Reglas claras y seguras
        </Styled.TeamMemberPosition>

        <Styled.TeamMemberDescription textColor={textColor}>
          Brinda reglas claras y seguras a las partes para trabajar. Mediante la
          tecnología que permite la descentralización, trazabilidad, inmutación
          de la información, encriptación, identidad digital y contratos
          inteligentes.
        </Styled.TeamMemberDescription>
      </Styled.ContainerTeamMember>
    </Styled.ContainerMembers>
  );
};

export default TokenizationFeatures;
