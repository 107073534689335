import React from "react";
import styled from "styled-components";
import { TopLine, Header1White, ParagraphSubtitleBlack } from "styles/common";
import colors from "styles/common/colors";
import lineBottom from "media/lines/gold-line-bottom-shadow-outside.png";
import custody from "media/icons/cripto-custody.png";
import useContactForm from "hooks/useCotactForm";

const SectionBlueBg = styled.div`
  width: 100%;
  background-color: white;
  color: #ffffff !important;
  padding-top: 50px;
  padding-bottom: 60px;
  position: relative;
`;

const WrapperPreFooter = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
  padding: 0 52px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0 24px;
    align-items: center;
    justfify-content: center;
  }
`;
const ContainerWrapperPreFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    align-items: center;
    justfify-content: center;
  }
`;

const ContainerRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    align-items: center;
    justfify-content: center;
  }
`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 1 100%;
  margin-top: 40px;

  @media (max-width: 640px) {
    margin-top: 10px;
    flex-direction: column;
  }
`;

const StyledButtonYellowExt = styled.a`
  color: black;
  background: ${colors.feanor_orange};
  text-decoration: none;
  font-family: "Bahnschift Light", sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  font-weight: 600;
  white-space: nowrap;
  padding: 15px 45px;
  margin-top: 10px;
  @media (max-width: 640px) {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 100px;
    height: 25px;
  }
`;
const CryptoactivesCustody = () => {
  const [ContactForm, openForm, setOpenForm, onCloseForm] = useContactForm();


  return (
    <SectionBlueBg>
      <ContactForm openForm={openForm} onCloseForm={onCloseForm}/>
      <div
        style={{ position: "absolute", top: "0px", left: "0", width: "100%" }}
      >
        <TopLine src={lineBottom} />
      </div>
      <WrapperPreFooter>
        <ContainerWrapperPreFooter>
          <img
            src={custody}
            width={100}
            height={100}
            alt="cripto-custodia-feanor"
          />
          <Header1White style={{margin: "10px 0 0 0"}}>Custodia de activos digitales</Header1White>
          <ParagraphSubtitleBlack>
            Tenga su wallet privado (Secure Cold Storage Wallet), custodiamos
            todo tipo de activos digitales con los máximos estándares de seguridad
            física y de ciberseguridad
          </ParagraphSubtitleBlack>
        </ContainerWrapperPreFooter>
        <ContainerRight>
          <ContainerButtons>
            <StyledButtonYellowExt onClick={setOpenForm}>
              Contactanos
            </StyledButtonYellowExt>
          </ContainerButtons>
        </ContainerRight>
      </WrapperPreFooter>
    </SectionBlueBg>
  );
};

export default CryptoactivesCustody;
