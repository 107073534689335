import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
import firebase from "firebase/app";
// Spinner Loader
import { Roller } from "react-awesome-spinners";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import swal from "sweetalert";

// Get FontAwesome Icons
import {
  faHandHoldingUsd,
  faUserLock,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
// Styled components
import {
  Section,
  BTCPartnersBox,
  BTCPartners,
  BTCText,
  LogoPartner,
  Wrapper,
  Space,
  Heading2,
  BoxBitcoin,
  Card,
  CardHeading,
  Advantages,
  LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

// import Styled from './styles';
import { Styled } from "./styles/index";
import {
  Header1,
  Header2,
  Header3,
  Paragraph,
  ParagraphSubtitle,
  TitleSection,
  Header1White,
  ParagraphSubtitleWhite,
  BlackContainer,
} from "styles/common/index.js";
import { TopLine, BottomLine } from "styles/common/index.js";
import topLine from "media/lines/gold-line-top.png";
import bottomLine from "media/lines/gold-line-bottom.png";

// Get the USD to PEN rate
import { ExchangeProvider } from "ExchangeContext";
// Get the time
import { DateProvider } from "DateContext";
// Get the Calculator component
import Calculator from "components/Calculator/index.js";
// Get the BTC to USD rate
import CurrencyPrice from "components/CurrencyPrice";
import bitcoin from "media/icons/icon_bitcoin_gold.png";
// import fiat from "media/fiat@2x.png";
// import time from "media/time@2x.png";

// Media images
import iconHouse from "media/icons/icon_house.png";
import iconJoin from "media/icons/icon_join_gold.png";
import iconSecurity from "media/icons/icon_security.png";
import iconCurrency from "media/icons/icon_currency_gold.png";
import qrcode from "media/icons/codigo-qr.png";

import heroGetStarted from "media/heros/girl_get_started_gold.png";
import heroWhyChoose from "media/heros/why_we_choose.png";
import heroUseCases from "media/heros/unlock_potential-old.png";

import logosignature from "media/home/signature.jpg";
import logoblockfactory from "media/home/blockfactory.png";
import logoilh from "media/home/logoilh.png";

import { Helmet } from "react-helmet";
import JoinToDecentralization from "components/JoinToDecentralization";
import styled from "styled-components";

import imgRecepcion from "media/icons/recepcion-pagos.png";
import imgRetiro from "media/icons/retiro-fondos.png";
import imgPlataforma from "media/icons/plataforma.png";
import imgAPI from "media/icons/api.png";
import useContactForm from "hooks/useCotactForm";
import TokenizationFeatures from "components/TokenizationFeatures";

// Print FontAwesome Icons
library.add(faHandHoldingUsd, faUserLock, faTachometerAlt);

const Home = ({ history }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [ContactForm, openForm, setOpenForm, onCloseForm] = useContactForm();
  const [count, setCount] = useState(0);

  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const showHoliday = () => {
    swal({
      text:
        "Estimados clientes por motivos de feriados, los Retiros y Abonos, serán procesado a partir del 5 de abril. Las Compras y Ventas de Bitcoins no habra ningún problema y pueden ser operados de manera regular.  Atte: Team Fëanor",
      title: "Aviso de feriado",
      button: {
        text: "Entendido",
        closeModal: true,
      },
      timer: 5000,
      allowOutsideClick: true,
      closeOnEsc: true,
    });
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    //scrollTop();

    const interval = setInterval(() => {
      //console.log(isVisible);
    }, 1000);

    setInterval(() => {
      setIsOpen(false);
    }, 4000);

    var today = new Date().getTime();
    var startDate = new Date(2020, 2, 31);
    var finishDate = new Date(2021, 3, 4);

    setTimeout(() => {
      if (today >= startDate.getTime() && today <= finishDate) {
        showHoliday();
      }
    }, 3000);
  }, []);

  if (count < 1) {
    setTimeout(() => {
      setCount(count + 1);
    }, 1000);
  }

  return (
    <>
      <ContactForm openForm={openForm} onCloseForm={onCloseForm} />
      <ExchangeProvider>
        <DateProvider>
          <>
            <Styled.HomeContainer>
              <Styled.HeroSection>
                <Styled.HeroText>
                  <Styled.TaglineHome style={{ color: "white" }}>
                    <PrimaryText style={{ color: "white" }}>
                      Sé parte de nuevas formas de generar proyectos y
                      beneficios con
                    </PrimaryText>
                    <SecondaryText> Tokenización de Activos</SecondaryText>
                  </Styled.TaglineHome>
                  <Styled.SubtaglineHome>
                    <PrimaryText style={{ color: "white" }}>
                      ¿Necesitas un impulso de Capital? La Tokenización puede
                      ser tu solución.
                    </PrimaryText>
                  </Styled.SubtaglineHome>
                  <Styled.ContainerButtons>
                    <Styled.StyledButtonBlueExt
                      onClick={() => setOpenForm(true)}
                    >
                      Contáctanos
                    </Styled.StyledButtonBlueExt>
                    <Styled.StyledButtonTransparent to="/quienes-somos">
                      Más información
                    </Styled.StyledButtonTransparent>
                  </Styled.ContainerButtons>
                </Styled.HeroText>
                <div>
                  <Styled.IframeVideo
                    // src="https://www.youtube-nocookie.com/embed/q50b99u-Yhk?controls=0&rel=0"
                    src="https://player.vimeo.com/video/759980913?h=db6e30c43f"
                    // title="YouTube video player"
                    // frameborder="0"
                    // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
                    // allowfullscreen
                  ></Styled.IframeVideo>
                </div>
              </Styled.HeroSection>
            </Styled.HomeContainer>

            <Styled.BgCircuitLinesLeft />
            
            <Styled.WrapperCalculatorSection>
              <div style={{ flex: 2 }}>
                <Header1White>
                  Los activos digitales son una puerta al futuro.{" "}
                </Header1White>
                <ParagraphSubtitleWhite
                  style={{ width: "80%", margin: "auto" }}
                >
                  Los activos digitales son una alternativa para diversificar
                  las inversiones, ofrece altos rendimientos para los que
                  apuestan a este mercado de innovación
                </ParagraphSubtitleWhite>
              </div>
              {/* <div style={{ flex: 1 }}>
                <Styled.ContainerButtons style={{ "padding-bottom": "20px" }}>
                  <Styled.StyledButtonYellow to={"/empresas"}>
                    Conoce más
                  </Styled.StyledButtonYellow>
                </Styled.ContainerButtons>
              </div> */}
            </Styled.WrapperCalculatorSection>

            <JoinToDecentralization />

            <BlackContainer style={{ padding: "50px 41px" }}>
              <Styled.ContainerMembers>
                {/* <Styled.ContainerTeamMember>
                  <Styled.ImageMember src={imgRecepcion} />
                  <Styled.TeamMemberPosition>
                    Recepcion de pagos
                  </Styled.TeamMemberPosition>

                  <Styled.TeamMemberDescription>
                    Podrá aceptar pagos con BTC por nuestro sistema checkout, a
                    través de email pago, o escaneando el codigo QR de su
                    comercio de forma presencial.
                  </Styled.TeamMemberDescription>
                </Styled.ContainerTeamMember> */}
                <Styled.ContainerTeamMember>
                  <Styled.ImageMember src={imgRetiro} />
                  <Styled.TeamMemberPosition>
                    Retiro de fondos
                  </Styled.TeamMemberPosition>

                  <Styled.TeamMemberDescription>
                    Al dia hoy contamos con el servicios de pagos en Panamá, con
                    los cuales podemos hacer un ACH desde nuestras cuentas en
                    Panamá en Dólares.
                  </Styled.TeamMemberDescription>
                </Styled.ContainerTeamMember>
                <Styled.ContainerTeamMember>
                  <Styled.ImageMember src={imgPlataforma} />
                  <Styled.TeamMemberPosition>
                    Plataforma de gestión
                  </Styled.TeamMemberPosition>

                  <Styled.TeamMemberDescription>
                    Tendrá acceso a un panel de control donde podrá ver todas
                    las transacciones que se van procesando y el estado de cada
                    una.
                  </Styled.TeamMemberDescription>
                </Styled.ContainerTeamMember>

                <Styled.ContainerTeamMember>
                  <Styled.ImageMember src={imgAPI} />
                  <Styled.TeamMemberPosition>
                    API para apps externas
                  </Styled.TeamMemberPosition>

                  <Styled.TeamMemberDescription>
                    Las API permitirán que conecte sus propias aplicaciones y
                    obtener datos para análisis o presentación en aplicaciones
                    internas de gestión corporativa.
                  </Styled.TeamMemberDescription>
                </Styled.ContainerTeamMember>
              </Styled.ContainerMembers>
            </BlackContainer>

            {/* TOKENIZACION */}
            <Styled.BgCircuitLinesLeft reverse={true} />

            <Styled.WrapperCalculatorSection>
              <div style={{ flex: 2 }}>
                <Header1White>
                  La tokenización de activos es el futuro de la inversión.
                </Header1White>
                <ParagraphSubtitleWhite
                  style={{ width: "80%", margin: "auto" }}
                >
                  La tokenización es un nuevo ecosistema de inversión para
                  desarrollar e impulsar proyectos. Utilizando la tecnología
                  Blockchain, NFTs y Contratos Inteligentes, integrada a una
                  estructura que genera confianza, reglas claras y seguridad.
                </ParagraphSubtitleWhite>
              </div>
              {/* <div style={{ flex: 1 }}>
                <Styled.ContainerButtons style={{ "padding-bottom": "20px" }}>
                  <Styled.StyledButtonYellow to={"/empresas"}>
                    Conoce más
                  </Styled.StyledButtonYellow>
                </Styled.ContainerButtons>
              </div> */}
            </Styled.WrapperCalculatorSection>

            <BlackContainer style={{ padding: "50px 41px" }}>
              {/* <Styled.ContainerMembers>
                <Styled.ContainerTeamMember>
                  <Styled.ImageMember src={imgRecepcion} />
                  <Styled.TeamMemberPosition>
                    Dividir un proyecto en partes
                  </Styled.TeamMemberPosition>

                  <Styled.TeamMemberDescription>
                    Se puede dividir en cualquier cantidad de partes, según el
                    acceso que quiera brindar a la inversión y el monto que se
                    necesita para el proyecto.
                  </Styled.TeamMemberDescription>
                </Styled.ContainerTeamMember>
                <Styled.ContainerTeamMember>
                  <Styled.ImageMember src={imgRetiro} />
                  <Styled.TeamMemberPosition>
                    Búsqueda de Capital
                  </Styled.TeamMemberPosition>

                  <Styled.TeamMemberDescription>
                    La tokenización tiene como objetivo principalmente ser una
                    alternativa de búsqueda de capital e inversión. Orientada a
                    los micro inversores y entregar beneficios por esa
                    inversión.
                  </Styled.TeamMemberDescription>
                </Styled.ContainerTeamMember>
                <Styled.ContainerTeamMember>
                  <Styled.ImageMember src={imgPlataforma} />
                  <Styled.TeamMemberPosition>
                    Reglas claras y seguras
                  </Styled.TeamMemberPosition>

                  <Styled.TeamMemberDescription>
                    Brinda reglas claras y seguras a las partes para trabajar.
                    Mediante la tecnología que permite la descentralización,
                    trazabilidad, inmutación de la información, encriptación,
                    identidad digital y contratos inteligentes.
                  </Styled.TeamMemberDescription>
                </Styled.ContainerTeamMember>
              </Styled.ContainerMembers> */}

              <TokenizationFeatures />

              <Styled.ContainerButtons>
                <Styled.StyledButtonBlueExt onClick={() => setOpenForm(true)}>
                  Contáctanos
                </Styled.StyledButtonBlueExt>
                <Styled.StyledButtonTransparent to="/quienes-somos">
                  Más información
                </Styled.StyledButtonTransparent>
              </Styled.ContainerButtons>
            </BlackContainer>

            {/* <Styled.ContainerSectionHeader>
              <TitleSection>Pasarelas de pago/cobro con crypto</TitleSection>
            </Styled.ContainerSectionHeader>

            <Styled.BgCircuitLinesLeftRight>
              <Styled.ParagraphCentered>
                ¡Reciba pagos hoy mismo con Bitcoin, de una forma muy simple!
              </Styled.ParagraphCentered>
            </Styled.BgCircuitLinesLeftRight>

            <Styled.WrapperCalculatorSection>
              <Styled.ContainerAboutSection>
                <Styled.HeroImgWrapper>
                  <Styled.HeroImg src={heroGetStarted} alt="Como Comenzar" />
                </Styled.HeroImgWrapper>
              </Styled.ContainerAboutSection>

              <Styled.ContainerRight>
                <Styled.ContainerDetail>
                  <Styled.ImageDetail src={iconJoin} />
                  <Styled.ContainerRight>
                    <Header3>1. Únete a Fëanor</Header3>
                    <Paragraph style={{ color: "white" }}>
                      Cree una cuenta para cada punto de cobro
                    </Paragraph>
                  </Styled.ContainerRight>
                </Styled.ContainerDetail>

                <Styled.ContainerDetail>
                  <Styled.ImageDetail src={qrcode} />
                  <Styled.ContainerRight>
                    <Header3>2. Imprima Código QR</Header3>
                    <Paragraph style={{ color: "white" }}>
                      Utilice su codigo QR para identificar sus puntos de cobro.
                    </Paragraph>
                  </Styled.ContainerRight>
                </Styled.ContainerDetail>

                <Styled.ContainerDetail>
                  <Styled.ImageDetail src={bitcoin} />
                  <Styled.ContainerRight>
                    <Header3>3. Reciba cobros con Bitcoin</Header3>
                    <Paragraph style={{ color: "white" }}>
                      Empieza a recibir tus Bitcoins y visualizar cada una de
                      las transacciones.
                    </Paragraph>
                  </Styled.ContainerRight>
                </Styled.ContainerDetail>

                <Styled.ContainerDetail>
                  <Styled.ImageDetail src={iconCurrency} />
                  <Styled.ContainerRight>
                    <Header3>4. Reciba el dinero en su cuenta</Header3>
                    <Paragraph style={{ color: "white" }}>
                      Podrás intercambiar tus bitcoins por dólares y
                      transferirlos a tu cuenta bancaria.
                    </Paragraph>
                  </Styled.ContainerRight>
                </Styled.ContainerDetail>
              </Styled.ContainerRight>
            </Styled.WrapperCalculatorSection> */}

            <Styled.ContainerSectionHeader>
              <TitleSection>¿Por qué elegir a Fëanor?</TitleSection>
            </Styled.ContainerSectionHeader>

            <Styled.BgCircuitLinesLeftRight></Styled.BgCircuitLinesLeftRight>
            <Styled.WrapperCalculatorSection>
              <Styled.ContainerAboutSection>
                <Styled.ContainerDetail>
                  <Styled.ImageDetail src={iconSecurity} />
                  <Styled.ContainerRight>
                    <Header3>Seguridad</Header3>
                    <Paragraph style={{ color: "white" }}>
                      Trabajamos en conjunto la TRM (monitoreo de transacciones
                      de para activos digitales) con el fin de asegurar la
                      integridad y seguridad de la plataforma. Tus fondos se
                      encuentran en buenas manos.{" "}
                    </Paragraph>
                  </Styled.ContainerRight>
                </Styled.ContainerDetail>
                <Styled.ContainerDetail>
                  <Styled.ImageDetail src={iconJoin} />
                  <Styled.ContainerRight>
                    <Header3>Estándares mundiales</Header3>
                    <Paragraph style={{ color: "white" }}>
                      {" "}
                      Contamos con KYC/AML y PLAF, de esta manera cumplimos los
                      estándares para validación y asegurar a nuestros clientes.
                    </Paragraph>
                  </Styled.ContainerRight>
                </Styled.ContainerDetail>
                <Styled.ContainerDetail>
                  <Styled.ImageDetail src={iconHouse} />
                  <Styled.ContainerRight>
                    <Header3>Juntos somos mejor</Header3>
                    <Paragraph style={{ color: "white" }}>
                      Buscamos la inclusión financiera a través de la inversión
                      en los productos que tokenizados, bajos los estándares
                      similares a los del sector financiero tradicional.
                    </Paragraph>
                  </Styled.ContainerRight>
                </Styled.ContainerDetail>
              </Styled.ContainerAboutSection>

              <Styled.ContainerRight>
                <Styled.HeroImgWrapper>
                  <Styled.HeroImg
                    src={heroWhyChoose}
                    alt="¿Por qué elegirnos?"
                  />
                </Styled.HeroImgWrapper>
              </Styled.ContainerRight>
            </Styled.WrapperCalculatorSection>

            <Styled.WrapperCalculatorSection>
              <Styled.ContainerButtons>
                <Styled.StyledButtonYellow to="/quienes-somos">
                  Conoce más
                </Styled.StyledButtonYellow>
              </Styled.ContainerButtons>
            </Styled.WrapperCalculatorSection>

            {/* <Styled.SectionGrayBg> */}
            <Styled.WrapperCalculatorSection>
              <Styled.ContainerAboutSection>
                <Header1>
                  Descubre el potencial de la tokenización para tu negocio
                </Header1>
                <ParagraphSubtitle>
                  Seguro te estarás preguntando en cómo te podrías beneficiar de
                  la Tokenización de Activos.
                </ParagraphSubtitle>
                <Styled.ContainerButtons>
                  {/* <Styled.StyledButtonYellow to="/empresas#nuestros-servicios">
                    Billetera Cryto
                  </Styled.StyledButtonYellow> */}
                  <Styled.StyledButtonBlueExt to="/tokenizacion-activos">
                    Tokenizacion de Activos
                  </Styled.StyledButtonBlueExt>
                </Styled.ContainerButtons>
              </Styled.ContainerAboutSection>
              <Styled.ContainerRight>
                <Styled.HeroImgWrapper>
                  <Styled.HeroImgStreched
                    src={heroUseCases}
                    alt="Casos de uso"
                  />
                </Styled.HeroImgWrapper>
              </Styled.ContainerRight>
            </Styled.WrapperCalculatorSection>
            {/* </Styled.SectionGrayBg> */}
            {/* <Styled.SectionWave src={waveGrayDown} /> */}

            {/* <Space /> */}

            {/* <Styled.WrapperCalculatorSection>
              <Styled.ContainerSectionHeader>
                <TitleSection>Confían en nosotros</TitleSection>
                <ParagraphSubtitle>
                  Ellos confían en nuestro trabajo de sumar cada día a más gente
                  al mundo de las monedas digitales
                </ParagraphSubtitle>
              </Styled.ContainerSectionHeader>
            </Styled.WrapperCalculatorSection> */}
            <Styled.TrustUsContainer>
              {/* <TopLine src={topLine}></TopLine> */}
              <Styled.TitleSectionTrustUs>
                Confían en nosotros
              </Styled.TitleSectionTrustUs>
              {/* <Styled.ParagraphSubtitleTrustUs>
                Ellos confían en nuestro trabajo de sumar cada día a más gente
                al mundo de las monedas digitales
              </Styled.ParagraphSubtitleTrustUs> */}
              <Styled.ContainerLogos>
                <a href="#" target="_blank">
                  <Styled.ImageBanks src={logoblockfactory} />
                </a>
                <a href="#" target="_blank">
                  <Styled.ImageBanks src={logoilh} />
                </a>
                {/* <a href="#" target="_blank">
                  <Styled.ImageBanks src={logosignature} />
                </a> */}
              </Styled.ContainerLogos>
            </Styled.TrustUsContainer>
          </>
        </DateProvider>
      </ExchangeProvider>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Fëanor | Tokenización de Activos para proyectos</title>
        <meta
          name="description"
          content="Experiencia fácil para la tokenización de activos. Opten capital para tu negocio de una manera segura y con altos rendimientos."
        />
      </Helmet>
    </>
  );
};

export default withRouter(Home);
