import styled from "styled-components";
import colors from "./colors";
import Select from "react-select";
import { HashLink } from "react-router-hash-link";
import bgCurrencyPrice from "media/bg/yellow-arrows-bg.jpg";

export const Header1 = styled.h1`
  font-family: "Bahnschift", sans-serif;
  color: ${colors.feanor_orange};
  font-size: 26x;
  font-weight: 600;
  margin-top: 0;
  text-align: center;
  @media screen and (max-width: 640px) {
    font-size: 22px;
  }
`;

export const Header2 = styled.h2`
  /* font-family: Montserrat, sans-serif; */
  font-family: "Bahnschift Light";
  color: ${colors.dark_blue_agente_btc};
  font-size: 30px;
  text-align: center;
  font-weight: 600;
`;

export const Header3 = styled.h3`
  /* font-family: Montserrat, sans-serif; */
  font-family: "Bahnschift Light";
  color: ${colors.feanor_orange};
  font-size: 27px;
  margin: 0;
  font-weight: 600;
  @media screen and (max-width: 640px) {
    font-size: 22px;
    text-align:center;
  }
`;

export const Header4 = styled.h4`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  line-height: 2.2;
`;

export const Header5 = styled.h5`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 14px;
  margin: 0;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  /* font-family: Montserrat, sans-serif; */
  font-family: "Bahnschift Light";
  color: white;
  color: rgb(0, 0, 0, 0.7);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

export const TitleSection = styled.h1`
  font-family: "Bahnschift Light", "Sans Serif";
  color: black;
  font-size: 25px;
  font-weight: 500;
  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

export const ParagraphSubtitle = styled.p`
  font-family: "Bahnschift", sans-serif;
  color: white;
  font-size: 25px;
  text-align: center;
  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
`;

export const WarningBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 13px;
  border-radius: 4px;
  background-color: #ffeece;
  margin-top: 20px;
  border: 1px solid #ffc85c;
  left: 0;
  right: 0;
  text-align: center;
  color: #3c4a5b;
`;

export const BtnBlueSm = styled(HashLink)`
  padding: 12px;
  background-color: #1a4bea;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  width: 120px;
  display: inline;
  cursor: pointer;
  margin: 0px 10px;
  text-decoration: none;
  font-weight: 600;
`;

export const Input = styled.input`
  position: relative;
  height: 30px;
  max-width: 400px;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  text-align: left;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #3c4a5b;
  box-sizing: border-box;
  text-align: left;
  padding-left: 10px;
  font-weight: 400;
  display: flex;
  outline: none;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TextData = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  text-align: left;
  font-family: Montserrat;
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  outline: none;
  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const TopLine = styled.img`
  width: 100%;
  @media (max-width: 900px) {
    height: 25px;
  }
  @media (max-width: 700px) {
    height: 20px;
  }
`;
export const BottomLine = styled.img`
  width: 100%;
  @media (max-width: 900px) {
    height: 25px;
  }
  @media (max-width: 700px) {
    height: 20px;
  }
`;
// Alternatives

export const Header1White = styled(Header1)`
  font-family: "Bahnschift Light", sans-serif;
  color: ${colors.feanor_orange};
  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

export const ParagraphSubtitleWhite = styled(ParagraphSubtitle)`
  color: #ffffff;
  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 17px;
  }
`;

export const ParagraphSubtitleBlack = styled(ParagraphSubtitle)`
  font-family: "Bahnschift Light", sans-serif;
  color: #000000;
  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 17px;
  }
`;


export const BlackContainer = styled.div`
  width: 100%;
  padding: 50px 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: black;
  @media (max-width: 800px) {
    padding: 20px;
  }
  @media (max-width: 640px) {
    padding: 20px;
  }
  @media (max-width: 350px) {
    padding: 20px;
  }
`;

export const TitleBlackSection = styled.div`
  color: ${colors.feanor_orange};
  font-size: 33px;
  font-family: "Bahnschift Light", sans-serif;
  @media (max-width: 900px) {
    font-size: 23px;
    margin-top: 20px;
  }
`;

export const DescriptionBlackSection = styled.div`
  color: white;
  font-size: 24px;
  font-family: "Bahnschift Light", sans-serif;
  @media (max-width: 600px) {
    font-size: 19px;
  }
`;

export const SectionDividerWithTitle = styled.div`
  height: 95px;
  /* margin-top: 80px; */
  /* margin-bottom: 80px; */
  background-image: url(${bgCurrencyPrice});
  background-repeat: no-repeat;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  /* background-position: 100px 0; */
  background-size: 100% 100%;
  .mobile {
    display: none;
  }
  @media (max-width: 1050px) {
    font-size: 25px;
    text-align: center;
  }
  @media (max-width: 900px) {
    height: 70px;
    font-size: 20px;
    text-align: center;
    padding: 0 150px;
  }
  @media (max-width: 600px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
      line-height: 17px;
    }
    height: 70px;
    font-size: 18px;
    text-align: center;
    padding: 0 90px;
    line-height: 20px;

  }
`;
const DescriptionSection = styled.div`
  color: white;
  font-size: 24px;
  font-family: "Bahnschift Light", sans-serif;
  @media (max-width: 600px) {
    font-size: 19px;
  }
`;
export const DescriptionSteps = styled(DescriptionSection)`
  @media (max-width: 1200px) {
    text-align: center;
  }
`;
export const FullWidth = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: auto;
  @media (max-width: 1200px) {
    width: 90%;
  }
`;
