import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faWindows } from "@fortawesome/free-brands-svg-icons";
import swal from "sweetalert";
import { DateContext } from "DateContext";
import { AuthContext } from "Auth";
import {
  CatcherBotonera,
  DisabledLink,
  EditButton,
} from "./styles";

import {
  AlertButton,
} from "styles/Styled";

import Alerta from "components/modals/Alerta";
import feanorapi from "feanorapi.js";
import { Redirect } from 'react-router-dom'


library.add(fab);

const Catcher = ({
  montoBitcoins,
  montoBitcoinsVenta,
  cantidadDolares,
  cantidadDolaresVenta,
  cantidadSoles,
  cantidadSolesVenta,
  currency,
  title,
  operacion,
  cambioBitcoin,
  cambioBitcoinVenta,
  cambioSoles,
  cambioSolesVenta,
  zzComisionBTC,
  zzComisionEnvio,
  disabled,
  history,
  calculatorViewer
}) => {
  // Styles for dashboard
  const buttonStyles = {
    display: "block"
  };

  const [isOpen, setIsOpen] = useState(false);
  const date = useContext(DateContext);

  const { currentToken } = useContext(AuthContext);

  const handleOperation = async () => {
    if (!currentToken) {
      swal({
        text: "Para realizar una transacción, porfavor ingresar",
        button: false
      });
      //history.push(`/login`);
      window.location = feanorapi.webapp+'/auth/login';

    } else {
      if(calculatorViewer.variables.BTCUSD == 0){
        swal({
          text: "Estamos calculando tu cotización. Intenta nuevamente en unos segundos o actualiza la página",
          button: false,
          timer: 4000
        });
      }else{
        const body = {
          types:  calculatorViewer.types,
          ammounts: calculatorViewer.ammounts,
          currencies: calculatorViewer.currencies,
          transactionType: calculatorViewer.transactionType
        };
        const data = {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentToken}`
          },
          body: JSON.stringify(body) // body data type must match "Content-Type" header
        };
        const endpoint = "/transaction";
        const url = `${feanorapi}${endpoint}`;
        fetch(url, data)
          .then(response => response.json())
          .then(responseJson => {
            swal({
              text: responseJson.msg,
              button: false,
              timer: 3000
            });
            if(responseJson.type === 'transaction-created'){
              const newTransactionId = responseJson.transaction._id;
              history.push(`/app/operation/edit/${newTransactionId}`);
            }
          })
          .catch(error => {
            swal({
              text: "Lo sentimos no se pudo realizar la operación",
              button: false,
              timer: 3000
            });
          });
      }
    }
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  if (disabled) {
    return (
      <CatcherBotonera>
        <DisabledLink>{title}</DisabledLink>
      </CatcherBotonera>
    );
  }
  const timeOpen =
  (date.hours >= 9 && date.hours < 19 && date.day >= 1 && date.day <= 5) || (date.hours >= 9 && date.hours < 13 && date.day === 6);
  return (
    <CatcherBotonera>
      {true ? (
        <EditButton
          onClick={handleOperation}
        >
          {title}
        </EditButton>
      ) : (
        <AlertButton type="button" onClick={handleOpenModal}>
          {title}
        </AlertButton>
      )}
      <Alerta isOpen={isOpen} closeModal={handleCloseModal} />
    </CatcherBotonera>
  );
};

export default withRouter(Catcher);
