import React from "react";
import { Link } from "react-router-dom";
import { HashLink as LinkHash } from "react-router-hash-link";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import bottomLine from "media/lines/gold-line-bottom.png";
import topLine from "media/lines/gold-line-top.png";
import { TopLine, BottomLine } from "styles/common/index";

import Styled, { LinkClassName } from "./styles";

library.add(fab);

const Footer = () => {
  return (
    <Styled.ContainerFooter>
      <TopLine src={topLine} />
      <Styled.FooterNav>
        <Styled.ContainerSectionFooter>
          <Styled.FooterHeading>Compañía</Styled.FooterHeading>
          <Styled.FooterMenu>
            {/* <Styled.LinkFooter to="/quienes-somos">Nosotros</Styled.LinkFooter> */}
            {/* <Styled.ContainerSocialLinks>
              <Styled.SocialLinkFooter
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink facebook"
              >
                <FontAwesomeIcon
                  color={"black"}
                  icon={["fab", "facebook-square"]}
                  className="iconFooter"
                />
              </Styled.SocialLinkFooter>
              <Styled.SocialLinkFooter
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink instagram"
              >
                <FontAwesomeIcon
                  color={"black"}
                  icon={["fab", "instagram"]}
                  className="iconFooter"
                />
              </Styled.SocialLinkFooter>
              <Styled.SocialLinkFooter
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink instagram"
              >
                <FontAwesomeIcon
                  color={"black"}
                  icon={["fab", "youtube"]}
                  className="iconFooter"
                />
              </Styled.SocialLinkFooter>
              <Styled.SocialLinkFooter
                target="_blank"
                rel="noopener noreferrer"
                href="#"
                className="socialLink instagram"
              >
                <FontAwesomeIcon
                  color={"black"}
                  icon={["fab", "whatsapp"]}
                  className="iconFooter"
                />
              </Styled.SocialLinkFooter>
            </Styled.ContainerSocialLinks> */}
            <Styled.companyDesc>
              Fëanor es una startup que busca ser la empresa{" "}
              <Styled.bold>líder a nivel</Styled.bold> mundial en desarrollar{" "}
              <Styled.bold>soluciones tecnológicas innovadoras</Styled.bold> que
              faciliten la{" "}
              <Styled.bold>creación y comercialización</Styled.bold> de activos
              digitales, y la inversión de capital en el mundo de los negocios.
            </Styled.companyDesc>
          </Styled.FooterMenu>
        </Styled.ContainerSectionFooter>

        <Styled.ContainerSectionFooter>
          <Styled.FooterHeading>Productos</Styled.FooterHeading>
          <Styled.FooterMenu>
            {/* <Styled.LinkFooter to="/como-empezar">
              Cómo empezar
            </Styled.LinkFooter> */}

            <Styled.LinkHash to="/wallet">Wallet</Styled.LinkHash>

            <Styled.LinkHash to="/empresas">
              Fëanor para empresas
            </Styled.LinkHash>
            {/* 
            <Styled.LinkHash to="/empresas#pasarela-pago">
              Pasarela de pago
            </Styled.LinkHash>

            <Styled.LinkHash to="/empresas#compra-criptoactivos">
              Compra de criptoactivos
            </Styled.LinkHash> */}

            <Styled.LinkHash to="/tokenizacion-activos">
              Tokenizacion de activos
            </Styled.LinkHash>
            {/* <Styled.LinkFooter to="/comisiones">Comisiones</Styled.LinkFooter>

  
            <Styled.LinkFooter to="#">
              Desarrolladores
            </Styled.LinkFooter> */}
          </Styled.FooterMenu>
        </Styled.ContainerSectionFooter>

        <Styled.ContainerSectionFooter>
          <Styled.FooterHeading>Legal</Styled.FooterHeading>
          <Styled.FooterMenu>
            <Styled.LinkFooter to="/terminos-y-condiciones">
              Terminos y condiciones
            </Styled.LinkFooter>
            <Styled.LinkFooter to="/politicas-kyc-aml">KYC</Styled.LinkFooter>
          </Styled.FooterMenu>
        </Styled.ContainerSectionFooter>
        <Styled.ContainerSectionFooter>
          {/* <Styled.FooterHeading>Otros</Styled.FooterHeading>
          <Styled.FooterMenu>
            <Styled.LinkFooter to="/conoce-bitcoin">
              Conoce Bitcoin
            </Styled.LinkFooter>

            <Styled.LinkFooter to="/ayuda">Ayuda</Styled.LinkFooter>
          </Styled.FooterMenu> */}
          {/* <Styled.FooterSocial> */}
          {/* <Styled.FooterMenu>SÍGUENOS</Styled.FooterMenu> */}

          {/* </Styled.FooterSocial> */}
        </Styled.ContainerSectionFooter>
      </Styled.FooterNav>
      <Styled.FooterCopy>
        © 2022 Fëanor Corp. Todos los derechos reservados.
      </Styled.FooterCopy>
      <BottomLine src={bottomLine} />
    </Styled.ContainerFooter>
  );
};

export default Footer;
