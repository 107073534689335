let GACode = "";
switch (process.env.REACT_APP_ENV_TYPE) {
  case 'development':
    GACode = "G-E7LWFTW997"
    break;
  case 'staging':
    GACode = "G-E7LWFTW997"
    break;
  case 'production':
    GACode = "G-E7LWFTW997"
    break;
  default:
    GACode = "G-E7LWFTW997"
}

export default GACode;
