import styled from "styled-components";
import colors from "styles/common/colors";
import lineTop from "./../../media/lines/gold-line-top.png";
import lineBottom from "./../../media/lines/gold-line-bottom.png";
export const VisorPrecio = styled.div`
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  opacity: 0.7;
  border-radius: 5px;
  font-weight: 600;
  font-family: Montserrat;
  @media screen and (max-width: 640px) {
    font-size: 15px;
  }

  @media screen and (max-width: 320px) {
    font-size: 15px;
  }
`;

export const CalculatorTabs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px 40px;
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }

  @media (max-width: 320px) {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
`;

export const CalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 500px;
`;

export const ContainerMinMax = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const HeadlineTitle = styled.div`
  width: 100%;
  font-family: "Bahnschift Light", sans-serif;
  text-align: left;
  font-size: 22px;
  color: black;
  opacity: 0.7;
  
  font-weight: 600;
  letter-spacing: 1px;
  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }

  @media screen and (max-width: 320px) {
    height: 60px;
    font-size: 18px;
  }
`;

export const ArrowDown = styled.span`
  margin-left: 8px;
  color: ${colors.feanor_orange};
`;
// export const ArrowDown = styled.img`
//   width: 16px;
//   height: 8px;
//   position: absolute;
//   right: 10px;
// `;

export const ArrowUp = styled.span`
  margin-left: 8px;
  color: ${colors.feanor_orange};
`;
// export const ArrowUp = styled.img`
//   width: 16px;
//   height: 8px;
//   position: absolute;
//   right: 10px;
//   transform: rotate(180deg);
// `;

export const EtiquetaPrecioCrypto = styled.div`
  width: 150px;
  position: absolute;
  right: 0px;
  height: 100%;
  /* background: rgba(255, 200, 92, 0.2); */
  background: ${colors.feanor_orange_light};
  font-weight: 600;
  font-size: 28px;
  border-radius: 0px 18px 18px 0px;
  /* color: rgba(255, 200, 92, 1); */
  color: rgba(0, 0, 0, 0.8);
  font-family: "Bahnschift Light";
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    font-size: 18px;
    width: 100px;
  }
`;

export const EtiquetaNombre = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

export const TitleCalculator = styled.div`
  width: 100%;
  font-family: "Bahnschift Light", sans-serif;
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
  @media(max-width: 500px) {
    font-size: 25px;
  }
`;

export const Icon = styled.span`
  margin: 5px;
`;
// export const Icon = styled.img`
//   width: 20px;
//   height: auto;
//   margin: 5px;
// `;

export const IconClickable = styled.span`
  margin: 5px;
  cursor: pointer;
`;
// export const IconClickable = styled.img`
//   width: 20px;
//   height: auto;
//   margin: 5px;
//   cursor: pointer;
// `;

export const EtiquetaPrecioFiat = styled.div`
  width: 150px;
  position: absolute;
  right: 0px;
  height: 100%;
  background: ${colors.feanor_orange_light};
  /* background: rgba(32, 201, 151, 0.2); */
  font-weight: 600;
  font-size: 24px;
  border-radius: 0px 18px 18px 0px;
  /* color: rgba(32, 201, 151, 1); */
  color: rgba(0, 0, 0, 0.8);
  font-family: "Bahnschift Light";
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    font-size: 18px;
    width: 100px;
  }
`;

export const IngresoPrecio = styled.input`
  text-align: left;
  align-items: center;
  width: 100%;
  margin: 1px 10px;
  border: none;
  color: black;
  /* color: #3c4a5b; */
  font-weight: 800;
  margin-top: 1px;
  background: transparent;
  transition: 0.2s color;
  &:hover {
    color: #866930;
  }
  @media screen and (max-width: 640px) {
    font-size: 24px;
    width: 200px;
  }
  
`;

export const Visor = styled.div`
  position: relative;
  height: 80px;
  width: 100%;
  font-size: 34px;
  margin: 5px 0 0px 0;
  /* border: 1px solid #3c4a5b; */
  border-radius: 18px;
  text-align: center;
  font-weight: 600;
  background-color: white;
  display: flex;
  /* &:hover {
    border: 1px solid blue;
  } */
  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    margin: 12px 0 0;
  }

  @media screen and (max-width: 640px) {
    height: 50px;
    text-align: center;
    font-size: 24px;
  }

  @media screen and (max-width: 320px) {
    height: 60px;
    line-height: 60px;
  }
`;

export const MinMaxElement = styled.div`
  margin: 20px;
  font-family: 'Bahnschift Light', 'Sans Serif';
  /* color: ${colors.dark_blu_btc}; */
  color: black;
  font-size: 16px;
  text-align: center;
`;

export const FooterCalculator = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: 'Bahnschift Light', 'Sans Serif';
  color: black;
  font-size: 18px;
`;

export const Convers = styled.div`
  *:focus {
    outline: none;
  }
  max-width: 100%;
  vertical-align: middle;
  text-align: center;
  background-color: ${colors.feanor_gold};
  align-items: center;
  position: relative;
  flex: 0 1 50%;
  box-shadow: 0 6px 9px 2px rgba(0, 0, 0, 0.06);
  padding: 50px 40px 10px 40px;
  box-sizing: bordex-box;
  /* border-radius: 8px  */
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 20px;
    background-image: url(${lineTop});
    top: 0;
    left: 0;
  }
  &:after {
    content: "";
    position: absolute;
    display: block;
    background-image: url(${lineBottom});
    width: 100%;
    height: 22px;
    bottom: 0;
    left: 0;
  }
  @media screen and (max-width: 768px) {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 40px 20px;
  }

  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`;
