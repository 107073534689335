import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
  Section,
  BTCPartnersBox,
  BTCPartners,
  BTCText,
  LogoPartner,
  Wrapper,
  Space,
  Heading2,
  BoxBitcoin,
  Card,
  CardHeading,
  Advantages,
  LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled, {
  BlackContainer,
  ContainerButtons,
  StyledButtonYellowExt,
} from "./styles";

import {
  Header1,
  Header3,
  Paragraph,
  ParagraphSubtitle,
  TitleSection,
  Header1White,
  ParagraphSubtitleWhite,
  SectionDividerWithTitle,
} from "styles/common/index.js";
import CurrencyPrice from "components/CurrencyPrice";
// Get the Chart component
import heroImg from "media/heros/wallet-gold.png";

import imgRetener from "media/icons/retener_icon.png";
import imgCompras from "media/icons/compras_icon.png";
import imgTransferencia from "media/icons/transferencia_icon.png";

import iconStep1 from "media/icons/paso1_otc_gold.png";
import iconStep2 from "media/icons/icon_join_gold.png";
import iconStep3 from "media/icons/icon_bitcoin_gold.png";
import iconStep4 from "media/icons/paso4_otc.png";
import iconStep5 from "media/icons/paso5_otc.png";

import { Helmet } from "react-helmet";
import colors from "styles/common/colors";

// Media images

import waveBlueUp from "media/waves/wave_blue_up.svg";
import BuyYourFirstBitcoin from "components/BuyYourFirstBitcoin";
import ContactForm from "components/ContactForm";

import feanorapi from "feanorapi";

const Wallet = ({ history }) => {
  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Styled.AboutContainer>
        <Styled.HeroSection>
          <Styled.HeroText>
            <Styled.TaglineAbout>
              <Styled.TitleSection>Wallet Fëanor</Styled.TitleSection>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <PrimaryText style={{ color: "white" }}>
                Tu propia billetera de activos digitales, gratis y fácil de usar
              </PrimaryText>
            </Styled.SubtaglineHome>
          </Styled.HeroText>
          <Styled.HeroImgWrapper>
            <Styled.HeroImg src={heroImg} alt="Network" />
          </Styled.HeroImgWrapper>
        </Styled.HeroSection>

        <div
          style={{
            display: "flex",
            "flex-direction": "column",
            alignItems: "flex-start",
          }}
        >
          {/* <Styled.TitleSection>Además</Styled.TitleSection>

          <Styled.DescriptionSection>
            Con tu billetera (wallet) podrás aprender de manera fácil como
            aprovechar todo el poder de los activos digitales y tokenización. En Fëanor
            la ofrecemos{" "}
            <b style={{ color: colors.feanor_gold }}>gratuitamente</b> a cada
            usuario validado.{" "}
          </Styled.DescriptionSection> */}
        </div>
      </Styled.AboutContainer>

      {/* <SectionDividerWithTitle>
        Envía y Recibe Activos digitales de cualquier parte del mundo
      </SectionDividerWithTitle>

      <Styled.BlackContainer>
        <Styled.ContainerTextTeam>
          <div
            style={{
              display: "flex",
              "flex-direction": "column",
              alignItems: "flex-start",
            }}
          >
            <Styled.DescriptionSection
              style={{ color: "white", textAlign: "left" }}
            >
              {" "}
              Con tu billetera envia y recibe básicamente activos digitales de cualquier
              parte del mundo (o fuera de este). Y luego puedes convertirlo a
              Dólares cuando lo desees.
            </Styled.DescriptionSection>
            <br></br>
            <Styled.DescriptionSection style={{ color: "white" }}>
              {" "}
              Tu usuario Fëanor te otorga una Wallet con dirección
              propia
            </Styled.DescriptionSection>
            <br></br>

            <Styled.TitleSection>¿Qué es 'Wallet'?</Styled.TitleSection>

            <Styled.DescriptionSection style={{ textAlign: "left" }}>
              Una wallet es el punto de partida para
              comenzar a operar con Activos digitales. Con ella podrás enviar y recibir
              activos digitales desde cualquier lado del mundo, las 24 horas del día.
            </Styled.DescriptionSection>
            <br />
            <Styled.DescriptionSection style={{ textAlign: "left" }}>
              Con tu billetera tu puedes disponer de tus fondos en cualquier
              momento. Una billetera vendría a ser como tener una cuenta
              bancaria en el banco, solo que la diferencia es que una billetera
              de activos digitales está disponible sin barreras y las transacciones son en
              minutos.
            </Styled.DescriptionSection>
          </div>
        </Styled.ContainerTextTeam>
      </Styled.BlackContainer> */}

      <SectionDividerWithTitle>
        ¿Cómo obtener mi propia Wallet Fëanor?
      </SectionDividerWithTitle>

      {/* <Styled.BlackContainer id="uses-cases-bitcoin">
        <Styled.ContainerTextTeam>
          <Styled.TaglineAbout>
            <PrimaryText>¿Cómo obtener mi propia Wallet Fëanor?</PrimaryText>
          </Styled.TaglineAbout>
        </Styled.ContainerTextTeam>
      </Styled.BlackContainer> */}

      <BlackContainer>
        <Styled.StepContainer>
          <Styled.ContainerStepLeft>
            <Styled.TaglineAbout>
              <Styled.ImageStep src={iconStep1} />
              <br></br>
              <Styled.TitleSection>Paso 1</Styled.TitleSection>
            </Styled.TaglineAbout>
          </Styled.ContainerStepLeft>
          <Styled.ContainerMissionRight>
            <Styled.SubtaglineHome>
              <Styled.DescriptionSteps>
                Regístrate en Fëanor, con tan solo tu correo y algunos datos.
              </Styled.DescriptionSteps>
            </Styled.SubtaglineHome>
          </Styled.ContainerMissionRight>
        </Styled.StepContainer>

        <Styled.StepContainer>
          <Styled.ContainerStepLeft>
            <Styled.TaglineAbout>
              <Styled.ImageStep src={iconStep2} />
              <br></br>
              <Styled.TitleSection>Paso 2</Styled.TitleSection>
            </Styled.TaglineAbout>
          </Styled.ContainerStepLeft>
          <Styled.ContainerMissionRight>
            <Styled.SubtaglineHome>
              <Styled.DescriptionSteps>
                Valida tu cuenta. Este paso es necesario para verificar tu
                identidad sin problemas.
              </Styled.DescriptionSteps>
            </Styled.SubtaglineHome>
          </Styled.ContainerMissionRight>
        </Styled.StepContainer>

        {/* <Styled.StepContainer>
          <Styled.ContainerStepLeft>
            <Styled.TaglineAbout>
              <Styled.ImageStep src={iconStep3} />
              <br></br>
              <Styled.TitleSection>Paso 3</Styled.TitleSection>
            </Styled.TaglineAbout>
          </Styled.ContainerStepLeft>
          <Styled.ContainerMissionRight>
            <Styled.SubtaglineHome>
              <Styled.DescriptionSteps>
                ¡Listo! Tu billetera ya está lista para usar, dentro de Fëanor
                podrás ver las funcionalidades. Si quieres saber más sobre como
                enviar y recibir activos digitales con tu billetera Fëanor, entra aquí.{" "}
              </Styled.DescriptionSteps>
            </Styled.SubtaglineHome>
          </Styled.ContainerMissionRight>
        </Styled.StepContainer> */}
        <ContainerButtons>
          <StyledButtonYellowExt href={`${feanorapi.webapp}/auth/register`}>
            Comenzar ahora
          </StyledButtonYellowExt>
        </ContainerButtons>
      </BlackContainer>

      {/* <Styled.SectionWave src={waveBlueUp}/> */}
      {/* <BuyYourFirstBitcoin /> */}

      <Helmet>
        <meta charSet="utf-8" />
        <title> Wallet | Fëanor</title>
        <meta
          name="description"
          content="Ten tu propia billetera Fëanor para tokenizacion en Panamá, gratis.."
        />
      </Helmet>
    </>
  );
};

export default withRouter(Wallet);
