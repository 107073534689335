import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
// Styled components
import {
  Wrapper,
  Heading2,
  Space,
  CenterBox,
  Code404,
  NotFoundParragraph,
} from "styles/Styled";

const NotFound = () => {
  return (
    <Wrapper>
      <Space />
      <Heading2>No encontrado</Heading2>
      <CenterBox>
        <Code404>{"404 :("}</Code404>
        <NotFoundParragraph>
          Lo sentimos, el contenido que estás buscando no está disponible en
          nuestra web.
        </NotFoundParragraph>
        <p>
          <Link to="/" className="selectedLink">
            Volver al inicio
          </Link>
        </p>
      </CenterBox>
      <Space/>
      <Space/>
    </Wrapper>
  );
};

export default withRouter(NotFound);
