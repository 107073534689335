import React from "react";
import { Appstore, Playstore, Close } from "./icons";
import "./popup.css";
import { Logo } from "components/Header/styles";
import logo from "media/logo-feanor.png";
import { useState } from "react";
import { useEffect } from "react";

export default function Popup() {
  const [isVisible, setisVisible] = useState(false);

  useEffect(() => {
    const permanentClose = localStorage.getItem("permanentClose");
    if (permanentClose) {
      return;
    }
    setTimeout(() => {
      setisVisible(true);
    }, 2000);
  }, []);
  return (
    <div className={`container ${isVisible ? "show" : ""}`}>
      <div
        className="close"
        onClick={() => {
          localStorage.setItem("permanentClose", "true");
          setisVisible(false);
        }}
      >
        <Close />
      </div>
      <Logo src={logo} alt="Fëanor" style={{ width: "80px", height: "70px", margin: "10px" }} />
      <h1 className="title">
        ¡Descarga la App de Fëanor y Controla tus Finanzas al Instante!
      </h1>
      <p className="description">
        Accede a tu saldo, realiza pagos, y controla tus gastos en tiempo real.
      </p>
      <div className="buttons">
        <a
          href="https://play.google.com/store/apps/details?id=io.feanor.feanorapp&hl=en_US"
          target="_blank"
          rel="noopener noreferrer"
          className="playstore button"
        >
          <Playstore />
        </a>
        <a
          href="https://apps.apple.com/cz/app/f%C3%ABanor-app/id6450010274"
          target="_blank"
          rel="noopener noreferrer"
          className="playstore button"
        >
          <Appstore />
        </a>
      </div>
    </div>
  );
}
