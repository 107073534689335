import React from "react";
import styled from "styled-components";
import { TopLine, Header1White, ParagraphSubtitleWhite } from "styles/common";
import colors from "styles/common/colors";
import { Link } from "react-router-dom";
import lineBottom from "media/lines/gold-line-bottom-shadow-outside.png";
import feanorapi from "feanorapi";

const SectionBlueBg = styled.div`
  width: 100%;
  background-color: ${colors.feanor_dark_bg};
  color: #ffffff !important;
  padding-top: 50px;
  padding-bottom: 60px;
  position: relative;
`;

const WrapperPreFooter = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
  padding: 0 52px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0 24px;
    align-items: center;
    justfify-content: center;
  }
`;
const ContainerWrapperPreFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    align-items: center;
    justfify-content: center;
  }
`;

const ContainerRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  @media (max-width: 600px) {
    align-items: center;
    justfify-content: center;
  }
`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 1 100%;
  margin-top: 40px;

  @media (max-width: 640px) {
    margin-top: 10px;
    flex-direction: column;
  }
`;

const StyledButtonYellowExt = styled(Link)`
  color: black;
  background: ${colors.feanor_gold};
  text-decoration: none;
  font-family: "Hahnschift Light", sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 10px;
  @media (max-width: 640px) {
    padding: 7px 50px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 120px;
    height: 35px;
  }
`;

const BuyYourFirstBitcoin = ({showLine = true }) => {
  return (
    <SectionBlueBg>
      <div
        style={{ position: "absolute", top: "0px", left: "0", width: "100%" }}
      >
        { showLine  && <TopLine src={lineBottom} /> }
      </div>
      <WrapperPreFooter>
        <ContainerWrapperPreFooter>
          <Header1White>Comprar tu primer bitcoin facil. </Header1White>
          <ParagraphSubtitleWhite>
            Comienza con tan solo 15$
          </ParagraphSubtitleWhite>
        </ContainerWrapperPreFooter>
        <ContainerRight>
          <ContainerButtons>
            <StyledButtonYellowExt href={`${feanorapi.webapp}/auth/register`}>
              Comenzar ahora
            </StyledButtonYellowExt>
          </ContainerButtons>
        </ContainerRight>
      </WrapperPreFooter>
    </SectionBlueBg>
  );
};

export default BuyYourFirstBitcoin;
