import React, { useEffect, useState } from 'react';
export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [token, setCurrentToken] = useState(localStorage.getItem("token-feanor"));
  const [expiration, setExpiration] = useState(localStorage.getItem("token-expires-feanor"));

  const updateToken = (token)=>{
    localStorage.setItem('token-feanor', token);
    setCurrentToken(token)
  }

  const updateExpiration = (expiration)=>{
    localStorage.setItem('token-expires-feanor', expiration);
    setExpiration(expiration);
  }

  useEffect(()=>{
    setCurrentToken(localStorage.getItem("token-feanor"));
  },[localStorage.getItem("token-feanor")])

  useEffect(()=>{
    setExpiration(localStorage.getItem("token-expires-feanor"));
  },[localStorage.getItem("token-expires-feanor")])

  return (
    <AuthContext.Provider
      value={{
        currentToken: token, updateToken: updateToken, expiration: expiration, updateExpiration: updateExpiration
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
