import styled from "styled-components";
import colors from "styles/common/colors.js";

export const TopHeader = styled.header`
  /* position: absolute; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* z-index: 20; */
  /* padding: 0 100px; */

  @media screen and (max-width: 640px) {
    left: 0;
    padding: 0;
    top: 170px;
  }
  @media screen and (max-width: 320px) {
    left: 0;
    padding: 0;
  }
`;

export const LinksContainer = styled.div`
width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Sun = styled.div`
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 400px;
  right: 50px;
  top: -260px;
  background: ${colors.yellow_agente_btc};
  box-shadow: 0 5px 30px 5px rgba(0, 0, 0, 0.1);
  animation-name: shine;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  z-index: 20;

  @keyframes shine {
    from {
      box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.1);
    }
    to {
      box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.1);
    }
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  /* z-index: 21; */
`;

export const GeneralLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const Item = styled.li`
  margin-left: 50px;
  position: relative;
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
`;

export const ItemBordered = styled.a`
  margin-left: 50px;
  position: relative;
  white-space: nowrap;
  font-size: 16px;
  border: 1px solid  ${colors.feanor_orange};
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px ;
  border-radius: 5px;
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 160px;
  margin-top: 5px;
  height: 80px;

  @media screen and (max-width: 640px) {
    max-width: 120px;
  }
`;

export const LogoutBtn = styled.button`
  background: #f2a900;
  border: none;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  padding: 6px 18px;
  border-radius: 24px;

  @media screen and (max-width: 640px) {
    padding: 3px 6px 4px;
  }
`;

export const Menu = styled.ul`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: space-between;
  /* padding-right: 220px; */
  box-sizing: border-box;
  @media screen and (max-width: 640px) {
    font-size: 14px;
    font-weight: 400;
    padding-inline-start: 10px;
  }
  @media screen and (max-width: 320px) {
    font-size: 11px;
  }
`;

export const TopLine = styled.img`
  width: 100%;
`;
export const BottomLine = styled.img`
  width: 100%;
`;

export default {
  TopHeader,
};

export const MenuOption = styled.span`
  margin-left: 15px;
`;

export const MenuOptionTitle = styled.span`
  color: ${colors.feanor_orange};
  text-transform: none;
  font-size: 17px;
  font-family: "Bahnschift Light", sans-serif;
`;
export const MenuOptionSubtitle = styled.span`
  text-transform: none;
  font-size: 15px;
  font-family: "Bahnschift Light", sans-serif;
`;
export const SmallMenuItem = styled.a`
  color: black;
  padding: 10px;
  display: block;
  padding: 13px 14px;
  &:hover {
    color: ${colors.feanor_orange};
  }
`;
