import React, { useEffect } from "react";
import { withRouter } from "react-router";

import { PrimaryText } from "styles/colorized";

import Styled from "./styles";
import {
  Header3,
  TitleSection,
  BlackContainer,
  TitleBlackSection,
  DescriptionBlackSection,
  SectionDividerWithTitle,
  DescriptionSteps,
} from "styles/common/index.js";

import heroImg from "media/heros/otc_feanor.png";
import tokenizacionBanner from "media/tokenizacion-feanor.png";

import iconStep1 from "media/icons/paso1_otc_gold.png";
import iconStep2 from "media/icons/paso2_otc_gold.png";
import iconStep3 from "media/icons/icon_bitcoin_gold.png";
import iconStep4 from "media/icons/paso4_otc_gold.png";

import { Helmet } from "react-helmet";

import colors from "styles/common/colors";
import CryptoactivesCustody from "./HowToUse";

import { TopLine } from "styles/common";
import lineBottom from "media/lines/gold-line-bottom-shadow-outside.png";
import imgRecepcion from "media/icons/recepcion-pagos.png";
import imgRetiro from "media/icons/retiro-fondos.png";
import imgPlataforma from "media/icons/plataforma.png";
import imgAPI from "media/icons/api.png";
const OTC = ({ history }) => {
  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BlackContainer>
        <Styled.HeroSection>
          <Styled.HeroText>
            <Styled.TaglineAbout>
              <PrimaryText style={{ color: colors.feanor_orange }}>
                Fëanor Tokenización de Activos
              </PrimaryText>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <PrimaryText style={{ color: "white" }}>
                Podrás tokenizar para desarrollar, impulsar y hacer crecer tu
                proyecto utilizando tecnologías innovadoras para la búsqueda de
                inversión.
              </PrimaryText>
            </Styled.SubtaglineHome>
          </Styled.HeroText>
          <Styled.HeroImgWrapper>
            <Styled.HeroImg src={heroImg} alt="Network" />
          </Styled.HeroImgWrapper>
        </Styled.HeroSection>
        {/* <div
          style={{
            display: "flex",
            "flex-direction": "column",
            alignItems: "flex-start",
          }}
        >
          <TitleBlackSection>A considerar</TitleBlackSection>

          <DescriptionBlackSection>
            Soportamos operaciones en dólares (USD). Y las criptomonedas
            principales del mundo: <br /> BTC - USDT - ETH.
          </DescriptionBlackSection>
        </div> */}
      </BlackContainer>

      {/* <SectionDividerWithTitle>
        Altos Límites, Exclusivo Servicio
      </SectionDividerWithTitle>

      <BlackContainer>
        <Styled.ContainerTextTeam>
          <div
            style={{
              display: "flex",
              "flex-direction": "column",
              alignItems: "flex-start",
            }}
          >
            <DescriptionBlackSection style={{ color: "white" }}>
              {" "}
              Este es un servicio altamente especializado y exclusivo, está
              diseñado para clientes con altos patrimonios y empresas.{" "}
            </DescriptionBlackSection>
            <br></br>
            <br></br>
            <DescriptionBlackSection
              style={{ color: "white", textAlign: "left" }}
            >
              {" "}
              Ofrecemos servicios directos de venta Libre (OTC) a clientes de
              Fëanor que estén interesados en comprar o vender grandes volúmenes
              de criptomonedas.
            </DescriptionBlackSection>
            <br></br>

            <TitleBlackSection>¿Qué es OTC?</TitleBlackSection>

            <DescriptionBlackSection style={{ textAlign: "left" }}>
              Operaciones OTC o “Over the Counter” son operaciones realizadas
              entre grandes empresas fuera de los mercados organizados.
            </DescriptionBlackSection>
            <br />
            <DescriptionBlackSection style={{ textAlign: "left" }}>
              Su importancia es creciente en las finanzas y la economía.
            </DescriptionBlackSection>
          </div>
        </Styled.ContainerTextTeam>
      </BlackContainer> */}

      <div id="nuestros-servicios">
        {" "}
        <SectionDividerWithTitle>
          <span className="desktop">
            Dentro de nuestro ecosistema brindamos:
          </span>
          <span className="mobile">Nuestro ecosistema:</span>
        </SectionDividerWithTitle>
      </div>

      <BlackContainer>
        <Styled.SectionTextCentered>
          Soporte para el modelo de negocio a tokenizar. Según las
          características del proyecto colaboramos para poder definir los montos
          de la tokenización del proyecto, valor de los tokens y objetivos del
          proyecto.
        </Styled.SectionTextCentered>
        <Styled.SectionTextCentered>
          Brindamos toda la tecnología por medio de blockchain, contratos
          inteligentes, generación de los NFTs y plataforma para poder realizar
          las transacciones. Gestionamos y automatizamos todo el procedimiento
          de KYC (Conozca su Cliente) teniendo oficiales de cumplimiento que
          validan que se cumplan los requisitos y condiciones de cada usuario.
        </Styled.SectionTextCentered>
        <Styled.SectionTextCentered>
          Trabajamos con la estructura de fideicomiso que brinda administración,
          organización, seguridad y transparencia para todo el proyecto.
        </Styled.SectionTextCentered>
        <Styled.SectionTextCentered>
          Contamos con los recursos para desarrollar y dar soporte a la
          comercialización electrónica de los tokens del proyecto, por medio de
          nuestro marketplace, el marketing digital y canales online
          desarrollados exclusivamente para cada proyecto, utilizando
          metodología y estrategia de ecommerce 360.
        </Styled.SectionTextCentered>
      </BlackContainer>

      <Styled.TeamContainer>
        <div
          style={{ position: "absolute", top: "0px", left: "0", width: "100%" }}
        >
          <TopLine src={lineBottom} />
        </div>
        <Styled.ContainerTextTeam>
          <Styled.TaglineAbout>
            <TitleBlackSection>
              Características principales de la Tokenización
            </TitleBlackSection>
          </Styled.TaglineAbout>

          <Styled.DescriptionTeam>
            <DescriptionBlackSection style={{ color: "black" }}>
              La tokenización es el futuro del desarrollo de proyectos e
              inversión
            </DescriptionBlackSection>
          </Styled.DescriptionTeam>

          <Styled.ContainerMembers>
            <Styled.ContainerMembers>
              <Styled.ContainerTeamMember>
                <Styled.ImageMember src={imgRecepcion} />
                <Styled.TeamMemberPosition>
                  Dividir un proyecto en partes
                </Styled.TeamMemberPosition>

                <Styled.TeamMemberDescription>
                  Se puede dividir en cualquier cantidad de partes, según el
                  acceso que quiera brindar a la inversión y el monto que se
                  necesita para el proyecto.
                </Styled.TeamMemberDescription>
              </Styled.ContainerTeamMember>
              <Styled.ContainerTeamMember>
                <Styled.ImageMember src={imgRetiro} />
                <Styled.TeamMemberPosition>
                  Búsqueda de Capital
                </Styled.TeamMemberPosition>

                <Styled.TeamMemberDescription>
                  La tokenización tiene como objetivo principalmente ser una
                  alternativa de búsqueda de capital e inversión. Orientada a
                  los micro inversores y entregar beneficios por esa inversión.
                </Styled.TeamMemberDescription>
              </Styled.ContainerTeamMember>
              <Styled.ContainerTeamMember>
                <Styled.ImageMember src={imgPlataforma} />
                <Styled.TeamMemberPosition>
                  Reglas claras y seguras
                </Styled.TeamMemberPosition>

                <Styled.TeamMemberDescription>
                  Brinda reglas claras y seguras a las partes para trabajar.
                  Mediante la tecnología que permite la descentralización,
                  trazabilidad, inmutación de la información, encriptación,
                  identidad digital y contratos inteligentes.
                </Styled.TeamMemberDescription>
              </Styled.ContainerTeamMember>
            </Styled.ContainerMembers>
          </Styled.ContainerMembers>
          {/* <Styled.BannerImgWrapper>
            <Styled.BannerImg src={tokenizacionBanner} alt="Network" />
          </Styled.BannerImgWrapper> */}
        </Styled.ContainerTextTeam>

        <Styled.ContainerSectionHeader>
          <TitleSection
            style={{ color: colors.feanor_orange, padding: "0 100px" }}
          >
            {/* “El bitcoin es un logro criptográfico notable, y la capacidad de
            crear algo que no es duplicable en el mundo digital tiene un valor
            enorme”<br></br>
            <br></br>- Eric Schmidt, ex CEO de Google */}
          </TitleSection>
        </Styled.ContainerSectionHeader>
      </Styled.TeamContainer>

      <Helmet>
        <meta charSet="utf-8" />
        <title> Tokenizacion | Fëanor</title>
        <meta
          name="description"
          content="Servicio de altas inversiones de activos digitales como bitcoin para instituciones e inversores de alto patrimonio."
        />
      </Helmet>
    </>
  );
};

export default withRouter(OTC);
