import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, CloseModal, ModalText, ModalButton, ModalWrapper, Heading3 } from 'styles/Styled';

const Alerta = ({ isOpen, closeModal }) => {

  if (!isOpen) {
    return null;
  }
  return ReactDOM.createPortal(
    <ModalWrapper>
      <Modal>
        <CloseModal onClick={closeModal}>X</CloseModal>
        <Heading3>Fuera de horario de atención</Heading3>
        <ModalText>
          Atendemos de lunes a viernes de 09:00 a 19:00 y sábados de 09:00 a 13:00.
          <br />
          Agradecemos su gentil comprensión.
          <br />
        </ModalText>
        <ModalButton onClick={closeModal}>Aceptar</ModalButton>
      </Modal>
    </ModalWrapper>,
    document.querySelector('#modal')
  );
};

export default Alerta;
