import React, { useEffect } from "react";
import { withRouter } from "react-router";

import { PrimaryText } from "styles/colorized";

import Styled from "./styles";
import {
  Header3,
  TitleSection,
  BlackContainer,
  TitleBlackSection,
  DescriptionBlackSection,
  SectionDividerWithTitle,
  DescriptionSteps,
} from "styles/common/index.js";

import heroImg from "media/heros/otc_feanor.png";

import iconStep1 from "media/icons/paso1_otc_gold.png";
import iconStep2 from "media/icons/paso2_otc_gold.png";
import iconStep3 from "media/icons/icon_bitcoin_gold.png";
import iconStep4 from "media/icons/paso4_otc_gold.png";

import { Helmet } from "react-helmet";

import colors from "styles/common/colors";
import CryptoactivesCustody from "./HowToUse";

import { TopLine } from "styles/common";
import lineBottom from "media/lines/gold-line-bottom-shadow-outside.png";
import imgRecepcion from "media/icons/recepcion-pagos.png";
import imgRetiro from "media/icons/retiro-fondos.png";
import imgPlataforma from "media/icons/plataforma.png";
import imgAPI from "media/icons/api.png";
import TokenizationFeatures from "components/TokenizationFeatures";
const OTC = ({ history }) => {
  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BlackContainer>
        <Styled.HeroSection>
          <Styled.HeroText>
            <Styled.TaglineAbout>
              <PrimaryText style={{ color: colors.feanor_orange }}>
                Fëanor Empresas
              </PrimaryText>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <PrimaryText style={{ color: "white" }}>
              {/* Te ayudamos a Tokenizar Activos y difundirlos a nivel global de una manera fácil y segura. */}
              Te ayudamos a Tokenizar Activos y difundirlos a nivel global de una manera fácil y segura.
              </PrimaryText>
            </Styled.SubtaglineHome>
          </Styled.HeroText>
          <Styled.HeroImgWrapper>
            <Styled.HeroImg src={heroImg} alt="Network" />
          </Styled.HeroImgWrapper>
        </Styled.HeroSection>
        {/* <div
          style={{
            display: "flex",
            "flex-direction": "column",
            alignItems: "flex-start",
          }}
        >
          <TitleBlackSection>A considerar</TitleBlackSection>

          <DescriptionBlackSection>
            Soportamos operaciones en dólares (USD). Y las criptomonedas
            principales del mundo: <br /> BTC - USDT - ETH.
          </DescriptionBlackSection>
        </div> */}
      </BlackContainer>

      {/* <SectionDividerWithTitle>
        Altos Límites, Exclusivo Servicio
      </SectionDividerWithTitle>

      <BlackContainer>
        <Styled.ContainerTextTeam>
          <div
            style={{
              display: "flex",
              "flex-direction": "column",
              alignItems: "flex-start",
            }}
          >
            <DescriptionBlackSection style={{ color: "white" }}>
              {" "}
              Este es un servicio altamente especializado y exclusivo, está
              diseñado para clientes con altos patrimonios y empresas.{" "}
            </DescriptionBlackSection>
            <br></br>
            <br></br>
            <DescriptionBlackSection
              style={{ color: "white", textAlign: "left" }}
            >
              {" "}
              Ofrecemos servicios directos de venta Libre (OTC) a clientes de
              Fëanor que estén interesados en comprar o vender grandes volúmenes
              de criptomonedas.
            </DescriptionBlackSection>
            <br></br>

            <TitleBlackSection>¿Qué es OTC?</TitleBlackSection>

            <DescriptionBlackSection style={{ textAlign: "left" }}>
              Operaciones OTC o “Over the Counter” son operaciones realizadas
              entre grandes empresas fuera de los mercados organizados.
            </DescriptionBlackSection>
            <br />
            <DescriptionBlackSection style={{ textAlign: "left" }}>
              Su importancia es creciente en las finanzas y la economía.
            </DescriptionBlackSection>
          </div>
        </Styled.ContainerTextTeam>
      </BlackContainer> */}

      <div id="nuestros-servicios">
        {" "}
        <SectionDividerWithTitle>Nuestros servicios</SectionDividerWithTitle>
      </div>

      {/* <BlackContainer>
        <Styled.SectionTextCentered>
          Nuestra propuesta consiste en facilitarles la aceptación de pagos en
          sus comercios con cripto-moneda BTC, realizar la conversión,
          depositarlos en las cuentas bancarias en USD. Nuestro servicio le
          permitirá mitigar sus riesgos en la fluctuación del precio del BTC.
        </Styled.SectionTextCentered>
      </BlackContainer> */}

      <Styled.TeamContainer>
        {/* <div
          style={{ position: "absolute", top: "0px", left: "0", width: "100%" }}
        >
          <TopLine src={lineBottom} />
        </div> */}
        <Styled.ContainerTextTeam>
          <Styled.TaglineAbout>
            <TitleBlackSection>Tokenización de Activos</TitleBlackSection>
          </Styled.TaglineAbout>

          <TokenizationFeatures textColor="black" />
        </Styled.ContainerTextTeam>

        {/* <Styled.ContainerTextTeam>
          <Styled.TaglineAbout>
            <TitleBlackSection>
              ¿Qué puedes hacer en nuestra Plataforma?
            </TitleBlackSection>
          </Styled.TaglineAbout>

          <Styled.DescriptionTeam>
            <DescriptionBlackSection style={{ color: "black" }}>
              Contamos con servicios de Checkout para procesar cobros en
              cripto-monedas.
            </DescriptionBlackSection>
          </Styled.DescriptionTeam>

          <Styled.ContainerMembers>
            <Styled.ContainerTeamMember>
              <Styled.ImageMember src={imgRecepcion} />
              <Styled.TeamMemberPosition>
                Recepcion de pagos
              </Styled.TeamMemberPosition>

              <Styled.TeamMemberDescription>
                Podrá aceptar pagos con BTC por nuestro sistema checkout, a
                través de email pago, o escaneando el codigo QR de su comercio
                de forma presencial.
              </Styled.TeamMemberDescription>
            </Styled.ContainerTeamMember>
            <Styled.ContainerTeamMember>
              <Styled.ImageMember src={imgRetiro} />
              <Styled.TeamMemberPosition>
                Retiro de fondos
              </Styled.TeamMemberPosition>

              <Styled.TeamMemberDescription>
                Al dia hoy contamos con el servicios de pagos en Panamá, con los
                cuales podemos hacer un ACH desde nuestras cuentas en Panamá en
                Dólares.
              </Styled.TeamMemberDescription>
            </Styled.ContainerTeamMember>
            <Styled.ContainerTeamMember>
              <Styled.ImageMember src={imgPlataforma} />
              <Styled.TeamMemberPosition>
                Plataforma de gestión
              </Styled.TeamMemberPosition>

              <Styled.TeamMemberDescription>
                Tendrá acceso a un panel de control donde podrá ver todas las
                transacciones que se van procesando y el estado de cada una.
              </Styled.TeamMemberDescription>
            </Styled.ContainerTeamMember>

            <Styled.ContainerTeamMember>
              <Styled.ImageMember src={imgAPI} />
              <Styled.TeamMemberPosition>
                API para apps externas
              </Styled.TeamMemberPosition>

              <Styled.TeamMemberDescription>
                Las API permitirán que conecte sus propias aplicaciones y
                obtener datos para análisis o presentación en aplicaciones
                internas de gestión corporativa.
              </Styled.TeamMemberDescription>
            </Styled.ContainerTeamMember>
          </Styled.ContainerMembers>
        </Styled.ContainerTextTeam> */}

        <Styled.ContainerSectionHeader>
          <TitleSection
            style={{ color: colors.feanor_orange, padding: "0 100px" }}
          >
            {/* “El bitcoin es un logro criptográfico notable, y la capacidad de
            crear algo que no es duplicable en el mundo digital tiene un valor
            enorme”<br></br>
            <br></br>- Eric Schmidt, ex CEO de Google */}
          </TitleSection>
        </Styled.ContainerSectionHeader>
      </Styled.TeamContainer>

      {/* <SectionDividerWithTitle id="pasarela-pago">
        Pasarela de pago en cripto-monedas.
      </SectionDividerWithTitle>

      <BlackContainer style={{ padding: "0 50px 100px 50px" }}>
        <Styled.StepContainer>
          <Styled.ContainerStepLeft>
            <Styled.TaglineAbout>
              <Styled.ImageStep src={iconStep1} />
              <TitleBlackSection>Paso 1</TitleBlackSection>
            </Styled.TaglineAbout>
          </Styled.ContainerStepLeft>
          <Styled.ContainerMissionRight>
            <Styled.SubtaglineHome>
              <Header3>1. Únete a Fëanor</Header3>
              <DescriptionSteps>
                Cree una cuenta para cada punto de cobro desde el panel
                administrativo.
              </DescriptionSteps>
            </Styled.SubtaglineHome>
          </Styled.ContainerMissionRight>
        </Styled.StepContainer>

        <Styled.StepContainer>
          <Styled.ContainerStepLeft>
            <Styled.TaglineAbout>
              <Styled.ImageStep src={iconStep2} />
              <TitleBlackSection>Paso 2</TitleBlackSection>
            </Styled.TaglineAbout>
          </Styled.ContainerStepLeft>
          <Styled.ContainerMissionRight>
            <Styled.SubtaglineHome>
              <Header3>2. Imprima el Código QR</Header3>
              <DescriptionSteps>
                Utilice su codigo QR para identificar sus puntos de cobro.
              </DescriptionSteps>
            </Styled.SubtaglineHome>
          </Styled.ContainerMissionRight>
        </Styled.StepContainer>

        <Styled.StepContainer>
          <Styled.ContainerStepLeft>
            <Styled.TaglineAbout>
              <Styled.ImageStep src={iconStep3} />
              <TitleBlackSection>Paso 3</TitleBlackSection>
            </Styled.TaglineAbout>
          </Styled.ContainerStepLeft>
          <Styled.ContainerMissionRight>
            <Styled.SubtaglineHome>
              <Header3>3. Reciba cobros con Bitcoin</Header3>
              <DescriptionSteps>
                Empieza a recibir tus Bitcoins y visualizar cada una de las
                transacciones.
              </DescriptionSteps>
            </Styled.SubtaglineHome>
          </Styled.ContainerMissionRight>
        </Styled.StepContainer>

        <Styled.StepContainer>
          <Styled.ContainerStepLeft>
            <Styled.TaglineAbout>
              <Styled.ImageStep src={iconStep4} />
              <TitleBlackSection>Paso 4</TitleBlackSection>
            </Styled.TaglineAbout>
          </Styled.ContainerStepLeft>
          <Styled.ContainerMissionRight>
            <Styled.SubtaglineHome>
              <Header3>4. Reciba el dinero en su cuenta</Header3>
              <DescriptionSteps>
                Podrás intercambiar tus bitcoins por dólares y transferirlos a
                tu cuenta bancaria.
              </DescriptionSteps>
            </Styled.SubtaglineHome>
          </Styled.ContainerMissionRight>
        </Styled.StepContainer>
      </BlackContainer> */}

      {/* <SectionDividerWithTitle id="compra-criptoactivos">
        Compra de Cryptoactivos
      </SectionDividerWithTitle> */}

      {/* <BlackContainer> */}
        {/* <Styled.StepContainer>
          <Styled.ContainerStepLeft>
            <Styled.TaglineAbout>
              <Styled.ImageStep src={iconStep1} />
              <TitleBlackSection>Paso 1</TitleBlackSection>
            </Styled.TaglineAbout>
          </Styled.ContainerStepLeft>
          <Styled.ContainerMissionRight>
            <Styled.SubtaglineHome>
              <Header3>1. Recepción de Pagos</Header3>
              <DescriptionSteps>
                Fëanor designara la cuenta donde se debera hacer la
                transferencia bancaria por el monto total.
              </DescriptionSteps>
            </Styled.SubtaglineHome>
          </Styled.ContainerMissionRight>
        </Styled.StepContainer>

        <Styled.StepContainer>
          <Styled.ContainerStepLeft>
            <Styled.TaglineAbout>
              <Styled.ImageStep src={iconStep2} />
              <TitleBlackSection>Paso 2</TitleBlackSection>
            </Styled.TaglineAbout>
          </Styled.ContainerStepLeft>
          <Styled.ContainerMissionRight>
            <Styled.SubtaglineHome>
              <Header3>2. Recibe su criptomoneda</Header3>
              <DescriptionSteps>
                Una vez ingresados los USD a través de la plataforma, los mismos
                se convierten a la criptomoneda escogida y guardados en su
                Wallet Fëanor.
              </DescriptionSteps>
            </Styled.SubtaglineHome>
          </Styled.ContainerMissionRight>
        </Styled.StepContainer>

        <Styled.StepContainer>
          <Styled.ContainerStepLeft>
            <Styled.TaglineAbout>
              <Styled.ImageStep src={iconStep3} />
              <TitleBlackSection>Paso 3</TitleBlackSection>
            </Styled.TaglineAbout>
          </Styled.ContainerStepLeft>
          <Styled.ContainerMissionRight>
            <Styled.SubtaglineHome>
              <Header3>3. Retiro de Fondos</Header3>
              <DescriptionSteps>
                Desde su Wallet Fëanor podra transferir su criptomoneda a la
                wallet que prefiera, y/o tambien ofrecemos custodia de
                Cryptoactivos.
              </DescriptionSteps>
            </Styled.SubtaglineHome>
          </Styled.ContainerMissionRight>
        </Styled.StepContainer> */}
      {/* </BlackContainer> */}

      {/* <div id="custodia-criptoactivos">
        <CryptoactivesCustody />
      </div> */}

      {/* <BuyYourFirstBitcoin /> */}

      <Helmet>
        <meta charSet="utf-8" />
        <title> Empresas | Fëanor</title>
        <meta
          name="description"
          content="Servicio de altas inversiones de activos digitales como bitcoin para instituciones e inversores de alto patrimonio."
        />
      </Helmet>
    </>
  );
};

export default withRouter(OTC);
