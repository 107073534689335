import styled from "styled-components";
import colors from "styles/common/colors";

export const EditButton = styled.button`
  border: none;
  outline: none;
  background-color: ${colors.feanor_orange};
  text-decoration: none;
  color: black;
  font-family: Montserrat;
  font-weight: 700;
  padding: 16px 32px;
  margin: 10px 0px;
  border-radius: 4px;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 480px) {
    font-size: 18px;
  padding: 10px 3px;

  }
`;

export const CatcherBotonera = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin-top: 25px;
  align-self: flex-start;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0;
  }
`;

export const DisabledLink = styled.span`
  background-color: grey;
  color: #ffffff;
  text-decoration: none;
  padding: 12px 32px;
  border-radius: 4px;

  font-size: 22px;
  margin: 12px 0;
  flex: 0 1 20%;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 20px;
    flex: 0 1 60%;
    padding: 8px 0;
    border: none;
  }
`;
