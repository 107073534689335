import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import colors from "styles/common/colors";
import bannerImg from "./../../../media/home/portada-feanor.jpg";
import yellow_arrows_bg from "./../../../media/bg/yellow-arrows-bg.jpg";
import circuit_lines_left from "./../../../media/lines/circuit-lines-left.png";
import bgHowToStart from "./../../../media/bg/bg-how-to-start.png";
import circuitLinesLeft from "./../../../media/lines/circuit-lines-left.png";
import bottomLine from "media/lines/gold-line-bottom.png";

const maxWidthPage = "1350px";
export const CalculatorContainer = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 24px;

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 0;
  }

  @media screen and (max-width: 640px) {
    padding: 40px 0;
  }
`;

export const HomeContainer = styled.div`
  width: 100%;
  margin: auto;
  min-height: 400px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  /* background-image: url(${bannerImg}); */
  background-color: ${colors.feanor_dark_bg};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (max-width: 750px) {
    background-image: none;
    background-color: ${colors.feanor_dark_bg};
  }
`;

const TaglineHome = styled.h3`
  font-family: "Bahnschift Light", sans-serif;
  font-size: 30px;
  font-weight: normal;
  margin: 0;
  width: 496px;
  font-weight: 700;
  @media screen and (max-width: 640px) {
    width: 100%;
    margin-top: 10px;
    font-size: 25px;
    font-weight: 600;
  }

  @media screen and (max-width: 320px) {
    margin-top: 10px;
    font-size: 18px;
  }
`;

const SubtaglineHome = styled.h5`
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  margin: 0;
  font-weight: 300;
  margin-top: 35px;
  @media screen and (max-width: 640px) {
    margin-top: 10px;
    font-size: 20px;
  }

  @media screen and (max-width: 320px) {
    margin-top: 10px;
    font-size: 18px;
  }
`;

export const HeroSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 78px;
  @media (max-width: 1212px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 640px) {
    padding: 30px 0;
    flex-wrap: wrap-reverse;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
  }
`;

export const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 1 55%;
  padding: 20 12px;

  @media (max-width: 640px) {
    order: 1;
    flex: 0 1 100%;
    align-items: center;
    text-align: center;
  }
`;

const HeroImgWrapper = styled.div`
  flex: 0 1 45%;
  /* padding-top: 100px; */

  @media (max-width: 640px) {
    order: 0;
    flex: 0 1 100%;
  }
`;

const HeroImg = styled.img`
  width: 100%;
  max-width: 600px;

  @media (max-width: 600px) {
    height: 300px;
    order: 0;
    flex: 0 1 100%;
  }
  @media (max-width: 400px) {
    height: 200px;
    order: 0;
    flex: 0 1 100%;
  }
`;
const HeroImgStreched = styled.img`
  width: 100%;
  max-width: 600px;
  height: 349px;
  width: 603px;
  @media (max-width: 600px) {
    height: 300px;
    order: 0;
    flex: 0 1 100%;
  }
  @media (max-width: 400px) {
    /* height: 200px;
    order: 0;
    flex: 0 1 100%; */
    display: none;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex: 0 1 100%;
  background-color: ${colors.feanor_dark_bg};
  padding: 40px 0 40px 0;

  @media (max-width: 640px) {
    /* margin-top: 10px */
    flex-direction: column;
  }
`;

const StyledButtonBlue = styled(Link)`
  color: white;
  background: ${colors.feanor_gold};

  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 10px;
  @media (max-width: 640px) {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 120px;
    height: 35px;
  }
`;

const StyledButtonBlueExt = styled(Link)`
  color: black;
  background: ${colors.feanor_orange};
  text-decoration: none;
  font-family: "Bahnschift Light", sans-serif;
  cursor: pointer;
  font-size: 17px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  padding: 15px 55px;
  margin-top: 10px;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    /* width: 100px; */
    height: 15px;
  }
`;

const StyledButtonYellow = styled(Link)`
  color: #3c4a5b;
  background: ${colors.feanor_gold};
  white-space: nowrap;
  text-decoration: none;
  font-family: "Bahnschift Light", sans-serif;
  font-size: 17px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 70px;
  color: black;
  padding: 15px 55px;
  margin: 20px;

  @media (max-width: 640px) {
    font-size: 13px;
    display: flex;
    justify-content: center;
    border-radius: 35px;
    width: 120px;
    height: 20px;
  }
`;

const StyledButtonYellowExt = styled.a`
  color: #3c4a5b;
  background: ${colors.feanor_orange};
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 10px;
  @media (max-width: 640px) {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 120px;
    height: 35px;
  }
`;

const StyledButtonYellowSec = styled(StyledButtonYellow)`
  border-radius: 8px;
`;

const StyledButtonYellowSecExt = styled(StyledButtonYellowExt)`
  border-radius: 8px;
`;

const StyledButtonTransparent = styled(Link)`
  background: trasparent;
  color: ${colors.feanor_orange};
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Bahnschift Light", sans-serif;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  /* font-weight: 600; */
  padding: 15px 55px;
  margin: 10px 0px 0px 0px;
  @media (max-width: 640px) {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 150px;
    height: 35px;
  }
`;

// Seccion Calculadora

const WrapperCalculatorSection = styled.div`
  width: 100%;
  /* max-width: ${maxWidthPage}; */
  margin: 0 auto 0 auto;
  box-sizing: border-box;
  padding: 40px 52px 30px 52px;
  background-color: ${colors.feanor_dark_bg};
  /* background-image: url(${bgHowToStart}); */
  /* background-repeat: no-repeat; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 60px 24px;
  }
`;

const BgCircuitLinesLeftRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 65px;
  margin: auto;
  background-color: ${colors.feanor_dark_bg};
  position: relative;
  background-image: url(${bgHowToStart});
  background-repeat: no-repeat;
  background-size: 100%;
`;

const BgCircuitLinesLeft = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 43px;
  /* max-width: ${maxWidthPage}; */
  margin: auto;
  margin-botom: 35px;
  background-color: ${colors.feanor_dark_bg};
  background-image: url(${circuitLinesLeft});
  background-repeat: no-repeat;
  transform: rotateY(${(props) => (props.reverse ? "180deg" : "0deg")});
`;
const ParagraphCentered = styled.div`
  color: white;
  width: 60%;
  font-size: 22px;
  font-family: "Bahnschift Light";
  text-align: center;
  background-color: transparent;
  @media (max-width: 900px) {
    padding: 24px;
  }
  @media (max-width: 600px) {
    width: 80%;
    font-size: 20px;
  }
  @media (max-width: 500px) {
    width: 80%;

    font-size: 18px;
  }
`;

const ContainerAboutSection = styled.div`
  width: 100%;
  padding-right: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 950px) {
    padding: 0;
  }
`;

const ContainerLogos = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 30px;
  grid-gap: 35px;
  grid-template-columns: auto auto auto;
  padding: 10px;
  @media (max-width: 600px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 400px) {
    grid-template-columns: auto;
  }
  @media screen and (max-width: 350px) {
    grid-gap: 0px;
  }
`;

const ImageDetail = styled.img`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 10px;
  @media (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
`;

const ImageBanks = styled.img`
  width: 150px;
  flex-shrink: 0;
  margin-top: 0;
  @media screen and (max-width: 500px) {
    width: 90px;
  }
`;

const ContainerDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: ce;
  margin: 15px 0px;
  @media (max-width: 500px) {
    margin: 10px 0px;
  }
`;

const ContainerRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 10px;
  @media (max-width: 950px) {
    flex-direction: column;
    padding: 0 24px;
  }
`;

// Generales

const ContainerSectionHeader = styled.div`
  width: 100%;
  text-align: center;
  padding: 25px 10px;
  background-repeat: no-repeat;
  background-image: url(${yellow_arrows_bg});
  background-size: 100% 100%;
`;

const ContainerSectionBody = styled.div`
  width: 100%;
  padding-right: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
`;

// Waves

const SectionWave = styled.img`
  width: 100%;
  display: flex;
`;

const SectionGrayBg = styled.div`
  width: 100%;
  padding: 0 50px;
`;

const SectionBlueBg = styled.div`
  width: 100%;
  background-color: #1a4bea;
  color: #ffffff !important;
  padding-top: 50px;
  padding-bottom: 60px;
`;

// Press Logos

const PressTitle = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #3c4a5b;
  padding-top: 50px;
`;

const PressSection = styled.div`
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0 24px;
  }
`;

const TrustUsContainer = styled.div`
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 13px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background-size: cover;
    @media (max-width: 900px) {
      z-index: -999;
    }
  }
`;

export const ParagraphSubtitleTrustUs = styled.p`
  font-family: "Bahnschift", sans-serif;
  color: black;
  font-size: 25px;
  text-align: center;
  margin-top: 0;
  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;

export const TitleSectionTrustUs = styled.h1`
  font-family: "Bahnschift Light", "Sans Serif";
  color: black;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0;
  @media screen and (max-width: 500px) {
    font-size: 22px;
  }
`;

/* SERVICIOS */
export const ContainerMembers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const ContainerTeamMember = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 300px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1000px) {
    flex-wrap: 32%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

const ImageMember = styled.img`
  max-width: 100%;
  height: 97px;
  /* border-radius: 50%; */
`;

const TeamMemberPosition = styled.h5`
  font-family: "Bahnschift Light", sans-serif;
  font-size: 22px;
  font-weight: normal;
  margin: 0;
  color: ${colors.feanor_orange};
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

const TeamMemberDescription = styled.h5`
  font-family: "Bahnschift Light", sans-serif;
  font-size: 18px;
  margin: 0;
  color: white;
  font-weight: 300;
  text-align: center;
  margin-top: 25px;
  @media screen and (max-width: 640px) {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 200;
    margin-right: 0px;
  }

  @media screen and (max-width: 320px) {
    margin-top: 10px;
    font-size: 13px;
  }
`;

const BgTopLine = styled.div`
  padding-top: 16px;
  /* padding-bottom: 43px; */
  /* max-width: ${maxWidthPage}; */
  margin: auto;
  height: 20px;
  background-color: ${colors.feanor_dark_bg};
  background-image: url(${bottomLine});
  background-repeat: no-repeat;
  background-size: 100% 25px;
  background-position: 0 bottom ;
`;

const IframeVideo = styled.iframe`
  width: 620px;
  height: 380px;
  border-width: 0px;

  @media screen and (max-width: 900px) {
    width: 470px;
    height: 300px;
  }
  @media screen and (max-width: 600px) {
    width: 400px;
    height: 280px;
  }
  @media screen and (max-width: 400px) {
    width: 290px;
    height: 220px;
  }
`;

export default {
  TeamMemberDescription,
  TeamMemberPosition,
  ImageMember,
  ContainerTeamMember,
  ContainerMembers,
  HomeContainer,
  TaglineHome,
  SubtaglineHome,
  HeroSection,
  HeroText,
  HeroImgWrapper,
  HeroImg,
  HeroImgStreched,
  StyledButtonBlue,
  StyledButtonBlueExt,
  StyledButtonYellow,
  StyledButtonYellowExt,
  StyledButtonYellowSec,
  StyledButtonYellowSecExt,
  StyledButtonTransparent,
  ContainerButtons,
  WrapperCalculatorSection,
  BgCircuitLinesLeftRight,
  BgCircuitLinesLeft,
  ParagraphCentered,
  ContainerAboutSection,
  ImageDetail,
  ImageBanks,
  ContainerDetail,
  ContainerRight,
  ContainerSectionHeader,
  ContainerSectionBody,
  SectionWave,
  SectionGrayBg,
  SectionBlueBg,
  ContainerLogos,
  CalculatorContainer,
  PressSection,
  PressTitle,
  TrustUsContainer,
  ParagraphSubtitleTrustUs,
  TitleSectionTrustUs,
  BgTopLine,
  IframeVideo,
};
